import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import CloudflareAppSec from '../../../containers/Services/Cloudflare/AppSec';

const cloudflareData = {
  title: 'Application Security with Cloudflare',
  seoParams: {
    ogImagePath: '/og-images/cloudflare-appsec.png',
    ogTitle: 'Application Security Services | Tech Holding',
    ogDesc: 'Protect Your Digital Frontiers with Tech Holding and Cloudflare.',
    ogPath: '/services/cloudflare/appsec',
  },
  description:
    'Our advanced application security services harness the power of Cloudflare’s intelligent security platform to provide protection across all layers:',
  intro: {
    heading: 'Protect Your Digital Frontiers with Always-On, Intelligent Defense',
    paragraphs: [
      'As applications become the core of digital business, the threat surface is expanding faster than ever. From volumetric DDoS attacks to zero-day exploits and sophisticated bots, application-layer threats are evolving and so must your defenses.',
      'As a <b>Cloudflare partner,</b> we deliver <b>enterprise-grade application security services</b> backed by Cloudflare’s global edge network. Our mission is to help organizations proactively secure their applications, APIs, and users without sacrificing speed or experience.',
    ],
  },
  sections: [
    {
      subheading: 'Protect Your Digital Frontiers',
      description:
        'Our advanced application security services harness the power of Cloudflare to offer:',
      columns: [
        {
          items: [
            {
              title: 'DDoS Protection',
              content:
                'Guard against the largest and most sophisticated attacks with Cloudflare’s network, capable of absorbing <b>terabit-scale DDoS</b> events. Automatically detect and mitigate L3–L7 attacks to ensure <b>zero downtime and business continuity.</b>',
            },
            {
              title: 'Web Application Firewall (WAF)',
              content:
                'Protect against OWASP Top 10 vulnerabilities and zero-day threats with Cloudflare’s <b>AI-powered, rules-based WAF.</b> Benefit from <b>pre-built managed rulesets, custom rules,</b> and real-time threat feeds.',
            },
            {
              title: 'Bot Management',
              content:
                'Defend against malicious automation such as <b>credential stuffing, scraping, fake account creation, and fraud.</b> Cloudflare’s bot management uses behavioral analysis, machine learning, and device fingerprinting to accurately detect and block bad bots while letting legitimate users through.',
            },
            {
              title: 'API Shield',
              content:
                'APIs are critical and increasingly targeted. Cloudflare’s API Shield protects your API endpoints with <b>mutual TLS (mTLS), schema validation,</b> and <b>rate limiting,</b> helping ensure only trusted clients access your services.',
            },
          ],
        },
        {
          items: [
            {
              title: 'Turnstile',
              content:
                'Replace outdated CAPTCHAs with <b>Turnstile</b>, a privacy-first, frictionless user validation system. Validate genuine users with minimal effort improving UX and reducing abandonment rates while maintaining strong bot protection.',
            },
            {
              title: 'Page Shield',
              content:
                'Monitor and block malicious client-side scripts that compromise browser security (e.g., <b>Magecart attacks</b>, formjacking). Page Shield gives you visibility and control over JavaScript running on your site.',
            },
            {
              title: 'SSL/TLS & Secure Edge Certificates',
              content:
                'Enforce HTTPS everywhere with <b>automated certificate management</b>, TLS 1.3, and end-to-end encryption. Ensure every interaction with your application is <b>encrypted and authenticated.</b>',
            },
          ],
        },
      ],
    },
  ],
  whyPartner: {
    heading: 'Why Work With Us?',
    subheading: 'Expertise, Support, and Innovation',
    description:
      'We bring together Cloudflare’s cutting-edge technology and our own proven expertise to create a secure digital perimeter around your applications whether hosted on-prem, in the cloud, or across a hybrid environment.',
    columns: [
      {
        items: [
          {
            title: 'Custom-Tailored Implementation',
            content:
              'Our engineers work with your team to deploy Cloudflare’s security services in a way that aligns with your existing architecture and future goals.',
          },
          {
            title: 'Compliance-Ready Frameworks',
            content:
              'We help you meet compliance requirements such as <b>PCI DSS, HIPAA, SOC 2, and GDPR</b> by implementing best-practice application security controls.',
          },
        ],
      },
      {
        items: [
          {
            title: '24x7 Monitoring & Managed Support',
            content:
              'Choose fully managed support or augment your internal teams. We offer <b>round-the-clock monitoring, incident response, and proactive tuning</b> of security policies.',
          },
        ],
      },
    ],
  },
  benefits: {
    heading: 'Benefits for Your Business',
    columns: [
      {
        items: [
          {
            title: 'Maintain Uptime & Reliability',
            content:
              'Keep services online and performant, even during peak attacks or traffic surges.',
          },
          {
            title: 'Protect Sensitive Data',
            content:
              'Prevent breaches and exfiltration with layered defenses across your app stack.',
          },
        ],
      },
      {
        items: [
          {
            title: 'Optimize End-User Experience',
            content:
              'Let legitimate users in faster by minimizing false positives and reducing friction.',
          },
          {
            title: 'Future-Proof Security Posture',
            content:
              'Stay ahead of emerging threats with a security platform that constantly evolves.',
          },
        ],
      },
    ],
  },
  callToAction: {
    heading: 'Let’s Secure What Matters Most',
    subheading: 'Your applications are your business. Let us help you protect them.',
    description:
      'Get in touch today to learn how we can <b>design, deploy, and manage Cloudflare’s application security</b> solutions for your environment.',
  },
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({
        json: PropTypes.shape({}).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

const AppSecPage = ({ data }) => (
  <CloudflareAppSec details={cloudflareData} companyQuote={data.contentfulCompanyQuote} />
);

AppSecPage.propTypes = propTypes;

export default AppSecPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
